<template>
  <div>
    <b-button variant="success" style="margin-right: 1em;" @click="openForm()">Criar Projeto</b-button>
    <b-button v-b-toggle.collapse-1 variant="primary">Filtros avançados</b-button>
    <b-collapse id="collapse-1" class="mt-2">
      <b-card>
        <b-row>
          <b-col cols="3">
              <b-form-group label="Projeto" label-for="immobile">
                  <b-form-input v-model="filters.nameFilter" />
              </b-form-group>
          </b-col>
          <b-col cols="3">
              <b-form-group label="Construtora" label-for="immobile">
                  <b-form-input v-model="filters.companyFilter" />
              </b-form-group>
          </b-col>
            <b-col cols="2">
                <b-form-group label="Data Inicio" label-for="payment-type">
                  <b-input-group>
                    <b-form-input
                      id="minReleaseDateFilter"
                      v-model="filters.minReleaseDateFilter"
                      v-mask="'##/##/####'"
                      placeholder=""/>
                        <b-input-group-append>
                          <b-input-group-text>
                            <b-link @click="filters.minReleaseDateFilter = undefined">
                              <b-icon icon="x" />
                            </b-link>
                          </b-input-group-text>
                        </b-input-group-append>
                  </b-input-group>                    
                </b-form-group>
            </b-col>
            <b-col cols="2">
                <b-form-group label="Data Fim" label-for="payment-type">
                   <b-input-group>
                    <b-form-input
                      id="maxReleaseDateFilter"
                      v-model="filters.maxReleaseDateFilter"
                      v-mask="'##/##/####'"
                      placeholder=""/>
                      <b-input-group-append>
                        <b-input-group-text>                          
                            <b-link @click="filters.maxReleaseDateFilter = undefined">
                              <b-icon icon="x" />
                            </b-link>
                        </b-input-group-text>
                      </b-input-group-append>
                   </b-input-group>
                </b-form-group>
            </b-col>   
          </b-row>
          <b-row>
            <div class="aling-buttons button-left">
              <b-button variant="primary" @click="clear()">Limpar</b-button>  
            </div>
            <div class="aling-buttons">
              <b-button variant="primary" @click="getProjects()">Pesquisar</b-button>  
            </div>
        </b-row>
      </b-card>
    </b-collapse>
    <div class="custom-search d-flex justify-content-end">
      <b-form-group>
        <div class="d-flex align-items-center">
          <label class="mr-1">Procurar</label>
          <b-form-input
            v-model="searchTerm"
            placeholder="Procurar"
            type="text"
            class="d-inline-block" />
        </div>
      </b-form-group>
    </div>

    <!-- table -->
    <vue-good-table
      id="table_projects"
      :columns="columns"
      :rows="rows"
      :rtl="direction"
      :search-options="{
        enabled: true,
        externalQuery: searchTerm,
      }"
      :pagination-options="{
        enabled: true,
        perPage: pageLength,
      }">
      <template slot="table-row" slot-scope="props">
        <!-- Column: Name -->
        <span v-if="props.column.field === 'name'" >
          <span >{{ props.row.project.name }}</span>
        </span>

        <span v-else-if="props.column.field === 'userConstructionCompanyName'" >
          <span >{{ props.row.userConstructionCompanyName }}</span>
        </span>

        <span v-else-if="props.column.field === 'releaseDate'" >
          <span >{{ formatDate(props.row.project.releaseDate) }}</span>
        </span>

        <!-- Column: Action -->
        <span v-else-if="props.column.field === 'action'">
          <b-link v-b-tooltip.hover.v-secondary title="Editar projeto" :to="{ name: 'project-edit', params: { id: props.row.project.id, path: '/adm/project/edit' }, }">
            <feather-icon icon="Edit2Icon" class="mr-50" />
          </b-link>
          <!--b-link v-b-tooltip.hover.v-secondary title="Excluir projeto" :to="{ name: 'project-delete', }">
            <feather-icon icon="XIcon" class="mr-50" />
          </b-link -->
        </span>

        <!-- Column: Common -->
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>
      <template slot="emptystate" styleClass="table_empty_data">
        <div class="table_empty_data">
          Sem dados
        </div>
      </template>
      <!-- pagination -->
      <template slot="pagination-bottom" slot-scope="props">
        <div class="d-flex justify-content-between flex-wrap">
          <div class="d-flex align-items-center mb-0 mt-2">
            <span > Mostrando </span>
            <b-form-select
              v-model="pageLength"
              :options="['3', '5', '10']"
              class="mx-1"
              @input="
                value => props.perPageChanged({ currentPerPage: value })
              " />
            <span class="text-nowrap"> de {{ props.total }} Resultados </span>
          </div>
          <div>
            <b-pagination
              :value="1"
              :total-rows="props.total"
              :per-page="pageLength"
              first-number
              last-number
              align="right"
              prev-class="prev-item"
              next-class="next-item"
              class="mt-1 mb-0"
              @input="value => props.pageChanged({ currentPage: value })">
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </div>
        </div>
      </template>
    </vue-good-table>
  </div>
</template>

<script>
import {  BInputGroupText, BInputGroupAppend, BPagination, BFormSelect, BLink, VBTooltip, BBadge, BCard, BCollapse, VBToggle, BButton, BRow, BCol, BFormGroup, BFormInput, BInputGroup} from 'bootstrap-vue'

import Ripple from 'vue-ripple-directive'
import { VueGoodTable } from 'vue-good-table'
import store from '@/store/index'
import { codeBasic } from './codeTable'
import ProjectService from '../services/Project/ProjectService'
import formatter from '@/mixins/formatter'

export default {
  components: {
    VueGoodTable,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BLink,
    BButton,
    BCard, 
    BCollapse,
    BRow,
    BCol, 
    BInputGroup,
    BInputGroupText, BInputGroupAppend,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
    'b-toggle': VBToggle
  },
  data() {
    return {
      pageLength: 10,
      dir: false,
      codeBasic,
      filters:{},
      columns: [
        {
          label: 'Nome do Projeto / Obra',
          field: 'name',
        },
        {
          label: 'Construtora',
          field: 'userConstructionCompanyName',
        },
        {
          label: 'Previsão de Entrega',
          field: 'releaseDate',
        },
        {
          label: 'Ação',
          field: 'action',
        },
      ],
      rows: [],
      searchTerm: '',
    }
  },
  computed: {
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        Inativo: 'light-danger',
        Ativo: 'light-success',
        Rejeitado: 'light-danger',
        Bloqueado: 'light-warning',
        Suspenso: 'light-info',
        /* eslint-enable key-spacing */
      }

      return status => statusColor[status]
    },
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
  },
  methods: {
    openForm() {
      this.$router.push({ path: '/adm/project/new' })
    },
    formatDate(dateString) {
      const date = new Date(dateString)
      return new Intl.DateTimeFormat('pt-BR').format(date)
    },
    remove() {

    },
    clear(){
      this.filters = {}
      this.getProjects()
    }, 
    async getProjects(){
      let filters = this.getFilters()
      const data = await ProjectService.getAll(filters)
      this.rows = data.data.result.items
    },

    getFilters(){
      let filters = `maxResultCount=1000&role=COMPANY`
      if(this.filters.companyFilter !== undefined) {
        filters = `${filters}&UserConstructionCompanyNameFilter=${this.filters.companyFilter}`
      }
      if(this.filters.maxReleaseDateFilter !== undefined) {
        filters = `${filters}&maxReleaseDateFilter=${formatter.formatToDate(this.filters.maxReleaseDateFilter, false)}`
      }
      if(this.filters.minReleaseDateFilter !== undefined) {
        filters = `${filters}&minReleaseDateFilter=${formatter.formatToDate(this.filters.minReleaseDateFilter, false)}`
      }
      if(this.filters.nameFilter !== undefined) {
        filters = `${filters}&nameFilter=${this.filters.nameFilter}`
      }
      return filters
    },
  },
  async created() {
    this.$store.commit('appConfig/UPDATE_NAV_MENU_HIDDEN', false)
    this.getProjects()   
  },

}
</script>

<style lang="scss" >
@import '@core/scss/vue/libs/vue-good-table.scss';
.text-center-position {
  margin-left: 45%;
}
body {
  background-color: #f8f8f8 !important;
}
#table_projects tr {
  border: 0;
  display: block;
  margin: 15px 3px;
  border: 1px #fff solid;
  border-radius: 10px;
}

#table_projects td {
  padding: 5px;
}
#table_projects tbody tr {
  background: #fff;
  transform: scale(1,1);
  -webkit-transform: scale(1,1);
  -moz-transform: scale(1,1);
  box-shadow: 0 2px 8px rgba(0,0,0,0.1);
  -webkit-box-shadow: 0 2px 8px rgba(0,0,0,0.1);
  -moz-box-shadow: 0 2px 8px rgba(0,0,0,0.1);
}

#table_projects  .vgt-table.bordered td,  #table_projects .vgt-table.bordered th {
  border: #fff !important;
}

//#table_projects table.vgt-table td {
//  padding: 0.75em 0.75em 0.75em 0.75em !important;
//}

#table_projects .vgt-left-align {
  text-align: left !important;
}

#table_projects table.vgt-table td {
  vertical-align: baseline !important;
  color: #6e6b7b;
}
#table_projects table.vgt-table thead {
  display: none;
}
#table_projects table.vgt-table tbody {
  font-size: 13px;
}
#table_projects table.vgt-table {
  background-color: #f8f8f8 !important;
  border: #f8f8f8 !important;
}
#table_projects .vgt-table th.line-numbers, #table_projects .vgt-table th.vgt-checkbox-col {
  padding: 0 2.75em 0 0.75em;
}
#table_projects table tbody td { max-width: 200px; min-width: 200px; }
#table_projects table tbody td:nth-child(1) { min-width: 200px; }
#table_projects table tbody td:nth-child(2) { min-width: 200px; }
#table_projects table tbody td:nth-child(3) { min-width: 200px; }
#table_projects table tbody td:nth-child(4) { max-width: 80px; min-width: 80px; float: right; margin-top: -55px; }
#table_projects a { color: #828282; }
#table_projects a:hover { color: var(--color); }
.space-icon { margin-left: 10px; }

.aling-buttons{
  margin-top: 1.6%;
}
.button-left{
  margin-left: 1%!important;
  margin-right: 1% !important;
}

</style>
